import { useEffect, useState } from 'react'

import { account } from '../../../../store.config'

function PromotionFlags() {
  const [cssFlags, setCssFlags] = useState<string>('')

  useEffect(() => {
    const fetchFlags = async () => {
      await fetch(
        `https://${account}.vtexassets.com/arquivos/promotion-flags.css`
      )
        .then((response) => response.text())
        .then((data) => setCssFlags(data))
        .catch(console.error)
    }

    fetchFlags()
  }, [])

  return (
    <style type="text/css" dangerouslySetInnerHTML={{ __html: cssFlags }} />
  )
}

export default PromotionFlags
