import CartToggle from 'src/components/cart/CartToggle'
import SearchInput from 'src/components/common/SearchInput'
import { ButtonIcon } from 'src/components/ui/Button'
import Icon from 'src/components/ui/Icon'
import Link from 'src/components/ui/Link'
import Logo from 'src/components/ui/Logo'
import LogoMobile from 'src/components/ui/Logo/LogoMobile'
import SlideOver from 'src/components/ui/SlideOver'
import { mark } from 'src/sdk/tests/mark'
import { useUI } from 'src/sdk/ui/Provider'
import { useFadeEffect } from 'src/sdk/ui/useFadeEffect'
import MiniCartIcon from 'src/components/ui/Icons/MiniCartIcon'
import BagIcon from 'src/components/ui/Icons/BagIcon'
import ButtonMyOrders from 'src/components/ui/Button/ButtonLink/ButtonMyOrders'
import ButtonSignIn from 'src/components/ui/Button/ButtonLink/ButtonSignIn/ButtonSignIn'
import { useState } from 'react'
import { useSession } from 'src/sdk/session'
import CallGeolocatorModal from 'src/components/common/Geolocator'
import { useMediaQuery } from 'react-responsive'

import storeConfig from '../../../../store.config'
import SidebarMenu from '../SidebarMenu'

const linksRedirect = [
  {
    text: 'Mi cuenta',
    url: storeConfig.accountUrl,
  },
  {
    text: 'Mis datos',
    url: `${storeConfig.secureSubdomain}api/io/account#/profile`,
  },
  {
    text: 'Mis pedidos',
    url: `${storeConfig.secureSubdomain}api/io/account#/orders`,
  },
  {
    text: 'Cerrar sesión',
    url: `${storeConfig.secureSubdomain}api/vtexid/pub/logout?scope=tatauy&returnUrl=/`,
  },
]

type NavProps = {
  isVisible: boolean
  person?: any
}

function NavbarSlider({ isVisible, person }: NavProps) {
  const { closeNavbar } = useUI()
  const { fade, fadeOut } = useFadeEffect()
  const [hideSidebarMenu, setHideSidebarMenu] = useState(false)
  const haveLogin = person?.id
    ? linksRedirect
    : [
        {
          text: 'Crea o ingresa tu cuenta',
          url: storeConfig.loginUrl,
        },
      ]

  return (
    <SlideOver
      isOpen
      fade={fade}
      onDismiss={fadeOut}
      size="full"
      direction="leftSide"
      className={`navbar__modal-content ${
        isVisible && 'navbar__modal-content--hide'
      }`}
      onTransitionEnd={() => fade === 'out' && closeNavbar()}
      onClick={() => closeNavbar()}
    >
      <div className="navbar__modal-body">
        <header
          className="navbar__modal-header"
          data-hide-sidebar-menu={hideSidebarMenu}
        >
          <div className="navlinks">
            <div className="navlinks__signin">
              <ButtonSignIn />
            </div>
            {haveLogin.map((link) => (
              <Link
                className="navlinks__login--link"
                href={link.url}
                key={link.text}
              >
                {link.text}
              </Link>
            ))}
          </div>
          <ButtonIcon
            aria-label="Close Menu"
            icon={<Icon name="Xmenu" width={18.67} height={18.67} />}
            onClick={fadeOut}
          />
        </header>

        <SidebarMenu
          hideSidebarMenu={hideSidebarMenu}
          setHideSidebarMenu={setHideSidebarMenu}
        />
      </div>
    </SlideOver>
  )
}

const ButtonSignInDropdown = (person: any) => {
  const [isOpen, setIsOpen] = useState(false)
  const givenName = person?.givenName
  const userName = (givenName && `Hola, ${givenName}`) ?? 'Perfil'
  const handleClick = () => {
    setIsOpen((b) => !b)
  }

  const handleClose = () => {
    setIsOpen(false)
  }

  const haveLogin = linksRedirect

  return (
    <>
      <button
        className="btn-signin-dropdown"
        onClick={handleClick}
        onKeyDown={handleClick}
      >
        <Icon name="User" width={18} height={18} weight="bold" />
      </button>
      {isOpen && (
        <div className="btn-signin-dropdown__container" onBlur={handleClose}>
          <div className="btn-signin-dropdown__item">
            <Icon name="User" width={18} height={18} weight="bold" />
            <h4 className="btn-signin-dropdown__username">{userName}</h4>
            <ButtonIcon
              aria-label="Close Menu"
              icon={<Icon name="Xmenu" width={18.67} height={18.67} />}
              onClick={handleClose}
            />
          </div>
          {haveLogin.map((link) => (
            <div
              key={`${link.text}__destopDropdown`}
              className="btn-signin-dropdown__item"
            >
              <Link className="btn-signin-dropdown__link" href={link.url}>
                {link.text}
              </Link>
            </div>
          ))}
        </div>
      )}
    </>
  )
}

function Navbar({ isVisible }: NavProps) {
  const isDesktopOrLaptop = useMediaQuery({
    query: '(min-width: 1280px)',
  })

  const { person } = useSession()

  const { navbar: displayNavbar, openNavbar } = useUI()

  return (
    <header
      className={`navbar layout__content-full ${
        !isDesktopOrLaptop && 'layout__content-full-mobile'
      }`}
    >
      <div data-testid="navheader" className="navbar__header layout__content">
        <section className="navbar__row">
          <>
            <div className="menu-icon" style={{ marginLeft: '10px' }}>
              <ButtonIcon
                data-fs-button-menu
                aria-label="Open Menu"
                icon={<Icon name="ListMobile" width={16} height={10} />}
                onClick={openNavbar}
              />
            </div>

            <Link
              href="/"
              aria-label="Go to Ta-Ta home"
              title="Go to Ta-Ta home"
              className="navbar__logo display-mobile"
            >
              <LogoMobile />
            </Link>

            <div className="header__link-buttons">
              <Link
                href="https://tata.com.uy/"
                className="header__button super"
                aria-label="Go to Super"
              >
                <MiniCartIcon />{' '}
                <span className="header__button-text">Super</span>
              </Link>
              <Link
                href="https://shop.tata.com.uy/"
                className="header__button shop"
                aria-label="Go to Shop"
              >
                <BagIcon /> <span className="header__button-text">Shop</span>
              </Link>
            </div>
          </>
        </section>
        <section className="navbar__header-box">
          <div className="navbar__header-container">
            <div className="navbar__header-itens hidden-mobile">
              <Link
                href="/"
                aria-label="Go to Ta-Ta home"
                title="Go to Ta-Ta home"
                className="navbar__logo"
              >
                <Logo />
              </Link>
              <SearchInput />
              <div className="navbar__buttons">
                <CallGeolocatorModal />
                <div className="navbar__wrapper">
                  {person?.id ? (
                    <ButtonSignInDropdown person={person} />
                  ) : (
                    <ButtonSignIn />
                  )}
                  <ButtonMyOrders />
                  <CartToggle />
                </div>
              </div>
            </div>

            <div className="navbar__container display-mobile">
              <div className="navbar__buttons">
                <CallGeolocatorModal />
                <div className="navbar__wrapper">
                  <ButtonMyOrders />
                  <CartToggle />
                </div>
              </div>
              <SearchInput />
            </div>
          </div>
        </section>
      </div>

      {displayNavbar && <NavbarSlider isVisible={isVisible} person={person} />}
    </header>
  )
}

Navbar.displayName = 'Navbar'
export default mark(Navbar)
