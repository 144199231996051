import axios from 'axios'
import { useEffect, useState } from 'react'

const UseLoggedUser = () => {
  const [sessionData, setSessionData] = useState<any>()

  useEffect(() => {
    const getSessionData = async () => {
      await axios
        .get('/api/getSession')
        .then((response) => {
          setSessionData(response.data)
        })
        .catch((error) => {
          console.error(error)
        })
    }

    getSessionData()
  }, [])

  useEffect(() => {
    if (!sessionData) {
      return
    }

    const hasSessionCookieEvent =
      JSON.parse(localStorage.getItem('login-success-session-id') ?? 'null') ===
      sessionData?.session

    if (!hasSessionCookieEvent) {
      window.dataLayer = window.dataLayer ?? []
      const eventImpression = {
        event: 'login-success',
        ecommerce: {
          sessionId: sessionData?.session,
          mensagem: 'usuario logado com sucesso',
        },
      }

      window.dataLayer.push(eventImpression)
      localStorage.setItem(
        'login-success-session-id',
        JSON.stringify(sessionData?.session)
      )
    }
  }, [sessionData])
}

export default UseLoggedUser
