import type { HTMLAttributes } from 'react'

import styles from './regionalization-bar.module.scss'

interface Props extends HTMLAttributes<HTMLDivElement> {
  classes: string
}

function RegionBar({ classes, ...otherProps }: Props) {
  return (
    <div
      data-fs-regionalization-bar
      className={`${classes} ${styles.fsRegionalizationBar}`}
      {...otherProps}
    />
  )
}

export default RegionBar
