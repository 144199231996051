import { graphql, useStaticQuery } from 'gatsby'

export const useGeolocations = () => {
  const { geolocations } = useStaticQuery(
    graphql`
      query Geolocations {
        geolocations {
          items {
            name
          }
        }
      }
    `
  )

  return geolocations.items
}
