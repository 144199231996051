/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react'
import { Locate, Edit } from 'src/components/ui/Icons'
import { validateSession, useSession, sessionStore } from 'src/sdk/session'

import GeoLocatorModal from './GeoLocatorModal'

const CallGeolocatorModal = () => {
  const HAS_WINDOW =
    typeof window !== 'undefined' && typeof window !== undefined

  const locationData = HAS_WINDOW
    ? JSON.parse(localStorage?.getItem('Location') ?? '{}')
    : '{}'

  const [newLocationData, setNewLocationData] = useState(locationData ?? {})
  const [selectedOption, setSelectedOption] = useState(locationData?.Name ?? '')
  const [showGeo, setShowGeo] = useState(false)
  const { isValidating, ...session } = useSession()

  useEffect(() => {
    if (HAS_WINDOW && !localStorage.getItem('Location')) {
      setShowGeo(true)
    }
  }, [])

  useEffect(() => {
    const resetLocation = async () => {
      if (HAS_WINDOW && !localStorage.getItem('Location')) {
        try {
          const originalSession = await validateSession({
            ...session,
            postalCode: null,
          })

          if (originalSession) {
            sessionStore.set(originalSession)
          }
        } catch (error) {
          throw new Error(error)
        }
      }
    }

    resetLocation()
  }, [session])

  return (
    <div className="navbar__geoLocate-wrapper">
      <button
        onClick={() => setShowGeo(true)}
        className="navbar__geolocate-button"
        aria-label="Geolocate Button"
      >
        <div className="navbar__geolocate-icon">
          <Locate />
        </div>
        <div className="navbar__geolocate-text">
          <p>{locationData?.description ?? 'TaTa'}</p>
        </div>
      </button>
      {showGeo && (
        <GeoLocatorModal
          setShowGeo={setShowGeo}
          setSelectedOption={setSelectedOption}
          selectedOption={selectedOption}
          newLocationData={newLocationData}
          setNewLocationData={setNewLocationData}
        />
      )}
      {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
      <div
        className="edit-geolocate-icon-container"
        onClick={() => {
          setShowGeo(true)
        }}
        onKeyDown={() => {
          setShowGeo(true)
        }}
      >
        <Edit className="edit__icon-nav-bar" />
      </div>
    </div>
  )
}

export default CallGeolocatorModal
