interface LocationData {
  tata: LocationsCookie
  tatauymontevideo: LocationsCookie
  tatauymulti300: LocationsCookie
  tatauymaldonado: LocationsCookie
  tatauypaysandu: LocationsCookie
  tatauysalto: LocationsCookie
  tatauytacuarembo: LocationsCookie
  tatauycolonia: LocationsCookie
  tatauymercedes: LocationsCookie
  tatauymelo: LocationsCookie
  tatauyminas: LocationsCookie
  tatauydurazno: LocationsCookie
  tatauyflorida: LocationsCookie
  tatauyrivera: LocationsCookie
  tatauyartigas: LocationsCookie
}

const locationData = {
  tata: {
    description: 'Seleccioná una localidad',
    postalCode: '11800',
    geoCoordinates: [-56.1672997, -34.8944046],
    sellerId: 'Ta-ta',
  },
  tatauymontevideo: {
    description: 'Montevideo y Ciudad de la Costa',
    postalCode: '11800',
    geoCoordinates: [-56.1672997, -34.8944046],
    sellerId: 'tatauymontevideo',
  },
  tatauymulti300: {
    description: 'La Paz y Las Piedras',
    postalCode: '11800',
    geoCoordinates: [-56.1672997, -34.8944046],
    sellerId: 'tatauymontevideo2',
  },
  tatauymaldonado: {
    description: 'Maldonado',
    postalCode: '20000',
    geoCoordinates: [-54.9598507, -34.9083456],
    sellerId: 'tatauymaldonado',
  },
  tatauypaysandu: {
    description: 'Paysandú',
    postalCode: '60000',
    geoCoordinates: [-58.0842077, -32.3170794],
    sellerId: 'tatauypaysandu',
  },
  tatauysalto: {
    description: 'Salto',
    postalCode: '50000',
    geoCoordinates: [-57.9636542, -31.3882904],
    sellerId: 'tatauysalto',
  },
  tatauytacuarembo: {
    description: 'Tacuarembó',
    postalCode: '45000',
    geoCoordinates: [-55.9896421, -31.7172353],
    sellerId: 'tatauytacuarembo',
  },
  tatauycolonia: {
    description: 'Colonia',
    postalCode: '70000',
    geoCoordinates: [-57.8389384, -34.4626803],
    sellerId: 'tatauycolonia',
  },
  tatauymercedes: {
    description: 'Mercedes',
    postalCode: '75000',
    geoCoordinates: [-58.0309316, -33.2494708],
    sellerId: 'tatauymercedes',
  },
  tatauymelo: {
    description: 'Melo',
    postalCode: '37000',
    geoCoordinates: [-54.1669927, -32.3640224],
    sellerId: 'tatauymercedes',
  },
  tatauyminas: {
    description: 'Minas',
    postalCode: '30000',
    geoCoordinates: [-55.2392657, -34.3755956],
    sellerId: 'tatauyminas',
  },
  tatauydurazno: {
    description: 'Durazno',
    postalCode: '97000',
    geoCoordinates: [-56.5257607, -33.3793025],
    sellerId: 'tatauydurazno',
  },
  tatauyflorida: {
    description: 'Florida',
    postalCode: '94000',
    geoCoordinates: [-56.2170497, -34.0963625],
    sellerId: 'tatauyflorida',
  },
  tatauyrivera: {
    description: 'Rivera',
    postalCode: '40000',
    geoCoordinates: [-55.545583, -30.9049554],
    sellerId: 'tatauyrivera',
  },
  tatauyartigas: {
    description: 'Artigas',
    postalCode: '55000',
    geoCoordinates: [-56.4683077, -30.4033534],
    sellerId: 'tatauyartigas',
  },
}

export const getSellerData = (sellerId: string): LocationsCookie => {
  if (sellerId === 'Ta-Ta') {
    sellerId = 'tata'
  }

  return locationData[sellerId as keyof LocationData]
}
