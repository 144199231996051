import { useState } from 'react'
import { List as UIList } from '@faststore/ui'
import Link from 'src/components/ui/Link'
import Accordion, { AccordionItem } from 'src/components/ui/Accordion'
import useWindowDimensions from 'src/components/hooks/useWindowDimensions'

type TFooterLinks = {
  footerLinks: FooterLinks
}

function LinksList({ links }: RedLinks) {
  return (
    <UIList>
      {links?.map((item, index) => (
        <li key={index}>
          <Link
            variant="footer"
            href={item?.URL ?? '/#'}
            aria-label={item?.title}
          >
            {item?.title}
          </Link>
        </li>
      ))}
    </UIList>
  )
}

function FooterLinks({ footerLinks }: TFooterLinks) {
  const [indicesExpanded, setIndicesExpanded] = useState<Set<number>>(
    new Set([])
  )

  const { isMobile } = useWindowDimensions()

  if (!footerLinks) {
    return null
  }

  const redLinks = footerLinks?.redLink

  const onChange = (index: number) => {
    if (indicesExpanded.has(index)) {
      indicesExpanded.delete(index)
      setIndicesExpanded(new Set(indicesExpanded))
    } else {
      setIndicesExpanded(new Set(indicesExpanded.add(index)))
    }
  }

  return (
    <section className="footer__links">
      {isMobile ? (
        <Accordion expandedIndices={indicesExpanded} onChange={onChange}>
          {redLinks.map((section, index) => (
            <AccordionItem
              key={section?.redtitle}
              isExpanded={indicesExpanded.has(index)}
              buttonLabel={section?.redtitle}
              testId={`footer-links-${index}`}
            >
              <LinksList links={section?.links} />
            </AccordionItem>
          ))}
        </Accordion>
      ) : (
        <>
          <div className="footer__links-columns">
            {redLinks?.map((section, index) => (
              <nav
                className={`footer__sections-${index}`}
                key={`${section?.redtitle} ${index}`}
              >
                <p className="text__title-mini">{section?.redtitle}</p>
                <LinksList links={section?.links} />
              </nav>
            ))}
          </div>
        </>
      )}
    </section>
  )
}

export default FooterLinks
