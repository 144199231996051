/* eslint-disable react/no-this-in-sfc */
/* eslint-disable prefer-destructuring */
import type { PropsWithChildren } from 'react'
import { createContext, lazy, Suspense, useState } from 'react'
import Footer from 'src/components/common/Footer'
import Navbar from 'src/components/common/Navbar'
import Toast from 'src/components/common/Toast'
import RegionalizationBar from 'src/components/regionalization/RegionalizationBar'
import PromotionFlags from 'src/components/sections/PromotionFlags'
import { useUI } from 'src/sdk/ui/Provider'

import StickBarCustom from './components/common/CustomStickBar/CustomStickBar'
import NavLinks from './components/common/Navbar/Navlinks'
import { useGlobalCmsComponentsQuery } from './components/hooks/useGlobalCmsContent'

import 'src/styles/pages/layout.scss'
import UseLoggedUser from './sdk/analytics/hooks/useLoggedUser'

const CartSidebar = lazy(() => import('src/components/cart/CartSidebar'))
const RegionModal = lazy(
  () => import('src/components/regionalization/RegionalizationModal')
)

type HeaderSizeContextProps = {
  headerSize: number | null
  setHeaderSize: (number: number | null) => void
  isTelemarketingBarVisible: boolean
  setIsTelemarketingBarVisible: (isTelemarketing: boolean) => void
}

export const HeaderSizeContext = createContext<HeaderSizeContextProps | null>(
  null
)

function Layout({ children }: PropsWithChildren) {
  const { stickBarTexts, collectionListData, footerLinks } =
    useGlobalCmsComponentsQuery()

  const { cart: displayCart, modal: displayModal } = useUI()

  const [isTelemarketingBarVisible, setIsTelemarketingBarVisible] =
    useState(false)

  const [headerSize, setHeaderSize] = useState<number | null>(null)

  UseLoggedUser()

  return (
    <>
      <HeaderSizeContext.Provider
        value={{
          headerSize,
          setHeaderSize,
          isTelemarketingBarVisible,
          setIsTelemarketingBarVisible,
        }}
      >
        <PromotionFlags />
        <StickBarCustom texts={stickBarTexts} />
        <div className="navBar__container-sticky-desktop hidden-mobile">
          <div className="navbar__sticky__header-mobile">
            <Navbar isVisible />
          </div>
          <NavLinks collectionListData={collectionListData} />
        </div>
        <div className="navbar__sticky__header-mobile display-mobile">
          <Navbar isVisible={false} />
        </div>
        <div className="navBar__NavLinks-mobile display-mobile">
          <NavLinks collectionListData={collectionListData} />
        </div>
        <Toast />
        <main>
          <RegionalizationBar classes="display-mobile" />
          {children}
        </main>
        <Footer footerLinks={footerLinks} />
      </HeaderSizeContext.Provider>

      {displayCart && (
        <Suspense fallback={null}>
          <CartSidebar />
        </Suspense>
      )}

      {displayModal && (
        <Suspense fallback={null}>
          <RegionModal />
        </Suspense>
      )}
    </>
  )
}

export default Layout
