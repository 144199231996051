import { graphql, useStaticQuery } from 'gatsby'

type TSection = {
  data: any
  name: string
}

export const useGlobalCmsComponentsQuery = () => {
  const data = useStaticQuery(
    graphql`
      query cmsGlobalQuery {
        cmsGlobal {
          sections {
            data
            name
          }
        }
      }
    `
  )

  const { sections } = data?.cmsGlobal

  const stickBarTexts = sections
    .filter((section: TSection) => section.name === 'StickBar')[0]
    ?.data?.allItems.map((item: any) => item.text)

  const collectionListData = sections.filter(
    (section: TSection) => section.name === 'CollectionList'
  )[0]?.data

  const footerLinks = sections.filter(
    (section: TSection) => section.name === 'FooterLinks'
  )[0]?.data

  return {
    stickBarTexts,
    collectionListData,
    footerLinks,
  }
}
